<template>
  <div class="absolute bottom-2 left-0 right-0">
    <div class="flex items-center overflow-x-auto pb-4 ltr:pl-8 rtl:pr-8">
      <old-product v-for="(product, index) in products" :key="`product-${index}`"
                   :data="sourceData" :hide-tags="true" :padding="8" :product="product"
                   class="me-3 !min-w-[120px] !max-w-[120px] rounded-lg overflow-hidden"
                   hide-fav-btn hide-manufacturer hide-reviews>
        <template #rating>
          <span></span>
        </template>
        <template #badge>
          <span v-if="product?.special[0]?.discount_ratio" class="absolute ltr:right-0 rtl:left-0 top-0 z-1 bg-secondary
              ltr:rounded-br-none rtl:rounded-bl-none ltr:rounded-bl-lg rtl:rounded-br-lg text-white text-[10px] py-1 px-2">
              {{ product?.special[0]?.discount_ratio }}
          </span>
        </template>
        <template #product-name="{ title }">
          <div class="van-multi-ellipsis--l2 text-xs">{{ title }}</div>
        </template>
        <template #image>
          <niceone-image v-if="product.thumb" :src="product.thumb" class="w-[80px] mx-auto"/>
        </template>
        <template #price>
          <price :increase-font-by="-2" :minHeight="28" :product="product" hide-discount-ratio/>
        </template>
        <template #cart-button>
          <cart-button :font-size="10" :height="30" :icon-size="8" :padding="8" :product="product"/>
        </template>
      </old-product>
    </div>
  </div>
</template>

<script setup>
import Price from "@/components/product/Price";
import OldProduct from "@/components/product/OldProduct";
import CartButton from "@/components/product/ProductCartButton";

defineProps({
  products: {
    type: Array,
    default: () => []
  }
})

const sourceData = {
  id: 'video_id',
  name: 'Video Page'
}
</script>

<style scoped>

</style>