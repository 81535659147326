<template>
  <nuxt-link :to="getProductUrl" class=" min-w-[170px] max-w-[450px] rounded-md bg-white relative"
             @click="onProductClicked">
    <slot name="badge"></slot>
    <div :class="{ 'opacity-50': outOfStock && !hideOverlay }" :style="`padding: ${padding}px`" class="p-3 rounded-sm">

      <slot name="divider"></slot>
      <div class="relative">
        <template v-if="!hideTags && !outOfStock">
          <tag-new v-if="product.is_new"/>
          <tag-dynamic :tag="product.tag"/>
        </template>
        <slot name="outOfStock">
          <template v-if="outOfStock">
            <tag-out-of-stock/>
          </template>
        </slot>
        <slot name="image">
          <div class="flex-center h-[180px] flex-[0_0_auto]">
            <niceone-image v-if="product.thumb" :src="product.thumb" class="max-w-[150px]"/>
          </div>
        </slot>
      </div>
      <van-text-ellipsis v-if="!hideManufacturer" :content="product.manufacturer"
                         class="text-[12px] text-color-[#bdbdbd] mb-1"/>
      <slot :title="product.name" name="product-name">
        <div class="min-h-[44px]">
          <div class="van-multi-ellipsis--l2 text-[13px] mb-[6px] font-niceone-light leading-5">
            {{ product.name }}
          </div>
        </div>
      </slot>
      <slot name="rating">
        <div class="flex items-center min-h-[10px]">
          <template v-if="product.rating">
            <span class="text-color-[#f5c523] text-[10px]">{{ product.rating }}</span>
            <van-icon class="px-1" color="#f5c523" name="star" size="10"/>
            <span class="text-[10px] text-color-[#bdbdbd]">{{ product.total_reviews }}</span>
          </template>
        </div>
      </slot>
      <slot name="price">
        <price :product="product"/>
      </slot>
    </div>
    <van-divider v-if="!hideCartBtn"/>
    <slot name="cart-button">
      <cart-button v-if="!hideCartBtn" :product="product" :quantity-key="quantityKey" :sourceData="data"/>
    </slot>
  </nuxt-link>
</template>

<script setup>
import Price from "@/components/product/Price"
import CartButton from "@/components/product/ProductCartButton.vue"
import TagNew from "@/components/tags/New";
import TagDynamic from "@/components/tags/Dynamic";
import TagOutOfStock from "@/components/tags/OutOfStock"
import {TRACKERS} from "@/constants/events";
import {track} from "@/composables/useTrackEvent";

const props = defineProps({
  product: {
    type: Object,
    default: () => {
    }
  },
  padding: {
    type: Number,
    default: 12
  },
  brandFontSize: {
    type: String,
    default: '12'
  },
  hideTags: {
    type: Boolean,
    default: false
  },
  hideCartBtn: {
    type: Boolean,
    default: false
  },
  hideOverlay: {
    type: Boolean,
    default: false
  },
  quantityKey: {
    type: String,
    default: 'quantity'
  },
  hideManufacturer: {
    type: Boolean,
    default: false
  },
  data: {
    type: Object,
    default: {}
  }
})
const route = useRoute()
const emit = defineEmits(['productClicked'])
const outOfStock = computed(() => !props?.product?.quantity)

const getProductUrl = computed(() => {
  if (props.product.seo_url_en || props.product.seo_url)
    return useNuxtApp().$routerUrl(props.product.seo_url_en ?
        props.product.seo_url_en : props.product.seo_url)
  return `${props.product.name
      .toLowerCase()
      .split(' ')
      .join('-')}-n${props.product?.id || props.product?.product_id}`
})

const onProductClicked = () => {
  track(TRACKERS.PRODUCT_CLICKED, {product: props.product, sourceData: props.data})
  if (route.name?.split('_')[0] === 'product-details') {
    track(TRACKERS.RECOMMENDED_PRODUCT, {product: props.product, sourceData: props.data})
  }
  emit('productClicked')
}
</script>