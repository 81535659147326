<template>
  <button :class="{ 'py-0': loading }" :disabled="isBtnDisabled"
          :style="`padding: ${padding}px; min-height: ${height}px; max-height: ${height}`"
          class="font-weight-500 w-full"
          @click.stop.prevent="addToCart">
    <van-loading v-if="loading" color="#997adb" size="16"/>
    <div v-if="!loading && !hasOptions && !outOfStock" class="flex items-center justify-between font-weight-500">
      <div></div>
      <div :style="`font-size: ${fontSize}px`" class="text-color-[#997adb]">
        {{ getCartProductCount ? $t('product.btn.item_in_cart') : $t('product.btn.add_to_cart') }}
      </div>
      <van-icon v-if="!getCartProductCount" :name="$publicPath('/images/plus.svg')" :size="iconSize"
                color="#997adb"></van-icon>
      <van-badge v-else :content="getCartProductCount" :max="9" color="#997adb" position="center"/>
    </div>
    <nuxt-link v-if="hasOptions && !outOfStock" :style="`font-size: ${fontSize}px`" :to="link"
               class="text-color-[#997adb] font-weight-500">
      {{ $t('product.pick_an_option') }}
    </nuxt-link>
    <div v-if="outOfStock && !loading" :style="`font-size: ${fontSize}px`" class="text-primary font-weight-500"
         @click.stop.prevent="addToWishlist">
      {{ inWishlist ? $t('product.btn.youll_be_notified') : $t('product.btn.notify_me') }}
    </div>
  </button>
</template>

<script setup>
import {addCartQuery, addWishlistMutation, removeWishlistMutation} from "@/plugins/API/cart"
import {useCartStore} from '@/store/cart'
import {useWishlistStore} from "@/store/wishlist";
import {TRACKERS} from "@/constants/events";
import {track} from "@/composables/useTrackEvent";

const props = defineProps({
  product: {
    type: Object,
    default: () => {
    }
  },
  sourceData: {
    type: Object,
    default: () => {
    }
  },
  fontSize: {
    type: Number,
    default: 12
  },
  iconSize: {
    type: Number,
    default: 10
  },
  padding: {
    type: Number,
    default: 12
  },
  height: {
    type: Number,
    default: 40
  },
  quantityKey: {
    type: String,
    default: 'quantity'
  },
  link: {
    type: String,
    default: ''
  }
})

const productId = computed(() => props.product?.id || props.product?.product_id)

const {isLoading, mutateAsync} = addCartQuery()
const addToCart = () => {
  const {option_detail = null} = props.product
  const option = !!option_detail && {
    [option_detail?.product_option_id]: option_detail?.product_option_value_id,
  };
  mutateAsync({id: productId.value, option}).then(() => {
    track(TRACKERS.ADD_TO_CART, {product: props.product, items_count: 1, sourceData: props.sourceData})
    track(TRACKERS.CTA_CLICK, {eventAction: 'Add to bag', eventLabel: '', product: props.product})
  })
}

const hasOptions = computed(() => !!props?.product?.has_option)
const outOfStock = computed(() => !props?.product?.[props.quantityKey])

const {getCartProductCountById} = useCartStore()
const getCartProductCount = computed(() => getCartProductCountById(productId.value, props.product))

// We want to disabled add to cart button in these cases so clicking on the product will lead to the product detail page 
const isBtnDisabled = computed(() => loading.value || hasOptions.value || getCartProductCount.value)
const loading = computed(() => isLoading.value || addWishlistLoading.value || removeWishlistLoading.value)

const wishlistStore = useWishlistStore()
const inWishlist = computed(() => !!wishlistStore.products.find(item => Number(productId.value) === Number(item.product_id)))

const {isLoading: addWishlistLoading, mutateAsync: addWishlistMutateAsync} = addWishlistMutation()
const {isLoading: removeWishlistLoading, mutateAsync: removeWishlistMutateAsync} = removeWishlistMutation()
const addToWishlist = () => {
  return inWishlist.value ? removeWishlistMutateAsync({id: productId.value}) : addWishlistMutateAsync({id: productId.value})
}
</script>