<template>
  <client-only>
    <full-page-loader :loading="isLoading"/>
    <div v-if="!isLoading" class="full-height">
      <swiper
          :modules="[SwiperNavigation]"
          class="h-full"
          direction="vertical"
          @slideChange="onSlideChange"
          @swiper="onSwiper"
      >
        <swiper-slide v-for="(item, index) in videos" :key="`video-item-${index}`">
          <div class="absolute left-0 right-0 top-0 z-1 px-3">
            <div class="flex items-center justify-between py-3">
              <niceone-image :src="$publicPath('/images/video/close.svg')" width="24" @click="emit('close')"/>
              <div class="flex items-center">
                <van-badge :content="count" :max="99" :position="$i18n.locale === 'en' ? 'top-right' : 'top-left'"
                           class="mx-3" color="#997adb" @click="showCart = true">
                  <niceone-image :src="$publicPath('/images/video/cart.svg')" width="16"/>
                </van-badge>
              </div>
            </div>
            <van-progress :percentage="activeIndex === index ? progress : 0" :show-pivot="false" color="#997adb"
                          stroke-width="3"/>
            <div class="text-white text-lg font-bold mt-3">{{ item?.title }}</div>
            <div class="text-white font-bold text-sm">{{ item?.description }}</div>
          </div>

          <video v-if="activeIndex === index" ref="videoRef" :muted="muted"
                 :poster="item?.thumbnail_url"
                 autoplay
                 class="w-full h-full absolute object-cover"
                 playsinline
                 @ended="playOtherVideo"
                 @loadedmetadata="onVideoLoaded"
                 @timeupdate="updateProgress"
          >
            <source
                :src="item?.video_url"
                type="video/mp4"/>
          </video>
          <background-image v-else :src="item?.thumbnail_url" class="w-full h-full"/>

          <div class="cursor-pointer absolute top-0 left-0 right-0 bottom-0 flex-center bg-[#00000040]"
               @click="toggleVideo">
            <niceone-image v-if="!isPlaying" :src="$publicPath('/images/video/play.svg')" width="50"
                           @click.stop.prevent="toggleVideo"/>
            <van-loading v-if="videoLoading" color="#997adb" size="56"/>
          </div>

          <video-products :products="item?.products"/>
        </swiper-slide>
      </swiper>
    </div>
  </client-only>
</template>

<script setup>
import {useCartStore} from "@/store/cart";
import {storeToRefs} from "pinia";
import {onLongPress} from '@vueuse/core'
import {getVideoDetailsByIdMutation} from "@/plugins/API/home";
import VideoProducts from "@/components/dynamic_components/video-slider/VideoProducts"
import BackgroundImage from "~/components/common/BackgroundImage.vue";

const props = defineProps({
  videoIndex: {
    type: [Number, String],
    default: 0
  },
  videoId: {
    type: [Number, String],
    default: 0
  }
})

const emit = defineEmits(['close'])

const videos = ref([])
const {isLoading, mutateAsync} = getVideoDetailsByIdMutation()
mutateAsync(props.videoId).then(({data}) => {
  videos.value = data.videos
})

const swiperRef = ref(null);
const onSwiper = (swiper) => {
  swiperRef.value = swiper;
  swiperRef.value?.slideTo(props.videoIndex)
};

const activeIndex = ref(0)
const onSlideChange = (e) => {
  activeIndex.value = e?.activeIndex;

  // Reset progress to 0
  reset()
}

const videoLoading = ref(true)
const muted = ref(true)

const cartStore = useCartStore()
const {count, showCart} = storeToRefs(cartStore)

const videoRef = ref(null);
const isPlaying = ref(true)

const toggleVideo = () => {
  if (videoRef.value[0]?.paused || videoRef.value[0]?.ended) {
    videoRef.value[0].play();
    isPlaying.value = true
  } else {
    videoRef.value[0].pause();
    isPlaying.value = false
  }
}

const longPressRef = ref(null)
onLongPress(
    longPressRef,
    toggleVideo
)

onUnmounted(() => {
  progress.value = 0
})

const progress = ref(0)
const updateProgress = () => {
  progress.value = (videoRef.value[0]?.currentTime / videoRef.value[0]?.duration) * 100 || 0
}

const onVideoLoaded = () => {
  muted.value = false
  videoLoading.value = false

  // Play the video
  setTimeout(() => {
    videoRef.value?.[0]?.play();
  }, 1000)
}

const playOtherVideo = () => {
  swiperRef.value.slideTo(activeIndex.value + 1)

  // Reset progress to 0
  reset()
}

const reset = () => {
  progress.value = 0;
  muted.value = true
  videoLoading.value = true
  isPlaying.value = true
  // videoRef.value?.[0]?.play();
}

watch(showCart, () => {
  toggleVideo()
})
</script>

<style scoped>

</style>