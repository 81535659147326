<template>
  <div class="absolute top-0 rtl:right-0 ltr:left-0 z-1 flex items-center">
    <div class="flex items-center h-[20px] bg-[#e35c02] px-1">
      <span class="text-white text-[12px] font-bold mb-[4px]">{{ $t("product_tags.new") }}</span>
    </div>
    <span :style="`border-${$i18n.locale === 'en' ? 'left' : 'right'}: 5px solid #e35c02`"
          class="border-t-[10px] border-b-[10px] border-y-[transparent]">
        </span>
  </div>
</template>

<style scoped>
/* Just dummy block */
</style>