<template>
  <div class="absolute top-0 right-0 left-0 bottom-0 z-1 flex-center">
    <div class="flex-center rounded-full h-6 w-[104px] bg-[#1C1C1C] px-2">
      <span class="text-xs text-white font-bold mb-[4px]">{{ $t('product.out_of_stock') }}</span>
    </div>
  </div>
</template>

<style scoped>
/* Just dummy block */
</style>